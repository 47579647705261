
import { defineComponent } from 'vue';
import RollResult from '@/models/RollResult';

import IndividualDie from './IndividualDie.vue';

export default defineComponent({
    name: 'DiceResult',
    components: {
        IndividualDie,
    },
    props: {
        roll: { type: RollResult, required: true },
        show: Boolean,
        static: Boolean,
        time: String,
    },
    data() {
        return {
            rollsCompleted: 0,
        };
    },
    methods: {
        rollDone(): void {
            this.rollsCompleted += 1;
        },
    },
    computed: {
        playerId(): string {
            return this.roll.playerId;
        },
        dice(): Array<{
            dieType: number;
            value: number;
            ignore: boolean;
        }> {
            return this.roll.toDiceArray();
        },
        diceSections(): {
            [key: string]: Array<{
                dieType: number;
                value: number;
                ignore: boolean;
            }>;
        } {
            const d20s = this.dice.filter((d) => d.dieType === 20);
            const others = this.dice.filter((d) => d.dieType !== 20);
            return {
                twenties: d20s,
                others: others,
            };
        },
        rollingDone(): boolean {
            return this.rollsCompleted === this.roll.numDice();
        },
        rollComponents(): string {
            const value = this.roll.value();
            const modifier = this.roll.modifier;
            const operator = modifier < 0 ? '-' : '+';

            return `${value} ${operator} ${Math.abs(modifier)} =`;
        },
    },
});
