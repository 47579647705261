
import Vue from 'vue';

import BattleMap from './components/BattleMap.vue';
import JoinGame from './components/JoinGame.vue';

import { Socket } from 'socket.io-client';

export default Vue.extend({
    name: 'App',
    components: {
        BattleMap,
        JoinGame,
    },
    data() {
        return {
            gameJoined: false,
            gameId: '',
            playerId: '',
            enterAsDm: false,
            socket: undefined as Socket | undefined,
        };
    },
    methods: {
        joinedGame(gameId: string, playerId: string, enterAsDm: boolean) {
            this.gameId = gameId;
            this.playerId = playerId;
            this.gameJoined = true;
            this.enterAsDm = enterAsDm;
        },
        setSocket(socket: Socket) {
            this.socket = socket;
        },
        disconnectSocket() {
            if (this.socket) {
                this.socket.disconnect();
            }
        },
        goBack() {
            this.gameJoined = false;
            this.disconnectSocket();
        },
    },
});
