
import Vue from 'vue';
import DiceD20 from 'vue-material-design-icons/DiceD20.vue';
import DiceD12 from 'vue-material-design-icons/DiceD12.vue';
import DiceD10 from 'vue-material-design-icons/DiceD10.vue';
import DiceD8 from 'vue-material-design-icons/DiceD8.vue';
import DiceD6 from 'vue-material-design-icons/DiceD6.vue';
import DiceD4 from 'vue-material-design-icons/DiceD4.vue';

export default Vue.extend({
    name: 'DiceRoller',
    components: {
        DiceD20,
        DiceD12,
        DiceD10,
        DiceD8,
        DiceD6,
        DiceD4,
    },
    data() {
        return {
            DICE: {
                d20: 20,
                d12: 12,
                d10: 10,
                d8: 8,
                d6: 6,
                d4: 4,
            },
            diceAmounts: {
                20: 0,
                12: 0,
                10: 0,
                8: 0,
                6: 0,
                4: 0,
            } as { [key: number]: number },
            modifier: 0,
            diceSize: 40,
            selectedAdvantageOption: 'normal' as
                | 'normal'
                | 'advantage'
                | 'disadvantage',
            advantageOptions: [
                { text: 'Normal', value: 'normal' },
                { text: 'Advantage', value: 'advantage' },
                { text: 'Disadvantage', value: 'disadvantage' },
            ],
        };
    },
    methods: {
        handleClick(event: MouseEvent, diceValue: number): void {
            event.preventDefault();
            this.diceAmounts[diceValue] = +this.diceAmounts[diceValue] + 1;
        },
        getRandomInt(min: number, max: number) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min);
        },
        rollDice(dmRoll: boolean) {
            let rollValues = {} as { [key: number]: Array<number> };
            for (let dieValue in this.diceAmounts) {
                if (!Object.keys(rollValues).includes(dieValue)) {
                    rollValues[dieValue] = [];
                }

                for (let i = 0; i < this.diceAmounts[dieValue]; i++) {
                    const roll = this.getRandomInt(1, +dieValue + 1);
                    rollValues[dieValue].push(roll);
                }
            }

            if (dmRoll) {
                this.$emit(
                    'dmRoll',
                    JSON.stringify({
                        dice: rollValues,
                        modifier: +this.modifier,
                        advantage: this.selectedAdvantageOption,
                        playerId: 'DM ROLL (only you see this)',
                    })
                );
            } else {
                this.$emit(
                    'diceRoll',
                    JSON.stringify({
                        dice: rollValues,
                        modifier: +this.modifier,
                        advantage: this.selectedAdvantageOption,
                        playerId: '++defaultPlayerId++',
                    })
                );
            }
        },
        clearDice() {
            const diceKeys = Object.keys(this.diceAmounts);
            diceKeys.forEach((k: string) => {
                const keyAsNumber = +k;
                this.diceAmounts[keyAsNumber] = 0;
            });
        },
        clearModifier() {
            this.modifier = 0;
        },
        clearAll() {
            this.clearDice();
            this.clearModifier();
        },
    },
    computed: {
        disableRoll(): boolean {
            const amounts = Object.values(this.diceAmounts).flat();
            for (let i = 0; i < amounts.length; i++) {
                if (amounts[i] > 0) {
                    return false;
                }
            }
            return true;
        },
        advantageHelperText(): string {
            const helperTexts = {
                normal: 'All d20s rolled will be included in the total',
                advantage: 'Only the highest d20 will be included in the total',
                disadvantage:
                    'Only the lowest d20 will be included in the total',
            };

            return helperTexts[this.selectedAdvantageOption];
        },
        isDM() {
            return this.$store.state.isDM;
        },
    },
});
