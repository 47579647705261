
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'DmRollResult',
    props: {
        result: String,
        show: Boolean,
        static: Boolean,
        time: String,
    },
    data() {
        return {
            rollDone: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.rollDone = true;
        }, 1000);
    },
    methods: {},
    computed: {
        critSuccess(): boolean {
            return this.result === 'NAT 20';
        },
        critFail(): boolean {
            return this.result === 'NAT 1';
        },
        normalResult(): boolean {
            return !this.critSuccess && !this.critFail;
        },
    },
});
