
import Marker from '@/models/Marker';
import Vue from 'vue';

export default Vue.extend({
    name: 'MarkerBuilder',
    props: {
        marker: Marker,
    },
    mounted() {
        if (this.marker) {
            this.form.name = this.marker.name;
            this.form.color = this.marker.color;
            this.form.fontColor = this.marker.fontColor;
            this.form.size = this.marker.radius;
            this.form.condition = this.marker.condition;
            this.form.height = this.marker.height;
        }
    },
    data() {
        return {
            form: {
                name: '',
                color: '#00BFFF',
                fontColor: '#000',
                size: 40,
                condition: '',
                height: 0,
            },
            formDefaults: {
                name: '',
                color: '#00BFFF',
                fontColor: '#000',
                size: 40,
                condition: '',
                height: 0,
            },
            sizeOptions: [
                { text: 'Tiny', value: 20 },
                { text: 'Small/Medium', value: 40 },
                { text: 'Large', value: 80 },
                { text: 'Huge', value: 120 },
                { text: 'Gargantuan', value: 160 },
            ],
        };
    },
    methods: {
        submit(event: SubmitEvent) {
            event.preventDefault();
            console.log('submitted');

            if (this.marker) {
                this.updateMarker();
            } else {
                this.createMarker();
            }
        },
        createMarker(): void {
            this.$emit('createMarker', this.form);

            this.form.name = '';
            this.form.condition = '';
            this.form.height = 0;
        },

        updateMarker(): void {
            this.marker.setName(this.form.name);
            this.marker.setColor(this.form.color);
            this.marker.setFontColor(this.form.fontColor);
            this.marker.setRadius(+this.form.size);
            this.marker.setCondition(this.form.condition);
            this.marker.setHeight(this.form.height);

            this.$emit('updateMarker');
            this.form = Object.assign({}, this.formDefaults);
        },
        removeMarker(): void {
            if (this.marker) {
                this.$emit('removeMarker', this.marker.id);
                this.form = Object.assign({}, this.formDefaults);
            }
        },
        deselectMarker(): void {
            this.$emit('cancelEdit');
            this.form = Object.assign({}, this.formDefaults);
        },
    },
    computed: {
        formHeight(): string {
            return +this.form.height > 0 ? `${this.form.height}ft` : '';
        },
    },
    watch: {
        marker: function (newMarker: Marker | undefined) {
            if (newMarker) {
                this.form.name = newMarker.name;
                this.form.color = newMarker.color;
                this.form.fontColor = newMarker.fontColor;
                this.form.size = newMarker.radius;
                this.form.condition = newMarker.condition;
                this.form.height = newMarker.height;
            }
        },
    },
});
