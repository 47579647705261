
import { defineComponent, type PropType } from 'vue';
import Marker from '@/models/Marker';
import draggable from 'vuedraggable';

export default defineComponent({
    name: 'LayerManager',
    components: {
        draggable,
    },
    props: {
        markers: { type: Array as PropType<Array<Marker>>, required: true },
    },
    created() {
        this.markerLayers = this.markers.slice().reverse();
    },
    data() {
        return {
            markerLayers: [] as Array<Marker>,
        };
    },
    methods: {
        truncateMarkerName(name: string) {
            if (name.length > 14) {
                return name.slice(0, 14) + '...';
            }
            return name;
        },
        dropLayer() {
            for (let i = 0; i < this.markerLayers.length; i++) {
                const marker = this.markerLayers[i];
                if (marker.layer !== this.markerLayers.length - i) {
                    marker.setLayer(this.markerLayers.length - i);
                }
            }
            this.$emit('updateMarkerLayers');
        },
    },
    watch: {
        markers: function (newVal: Array<Marker>) {
            this.markerLayers = newVal.slice().reverse();
        },
    },
});
