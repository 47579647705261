
import { defineComponent } from 'vue';
import DiceResult from './DiceResult.vue';
import DmRollResult from './DmRollResult.vue';

export default defineComponent({
    name: 'RollLog',
    components: {
        DiceResult,
        DmRollResult,
    },
    props: ['rolls', 'dmRolls'],
    methods: {
        time(timestamp: number): string {
            return new Date(timestamp).toLocaleString();
        },
    },
    computed: {
        allRolls(): any[] {
            const normalRolls = this.rolls.map((rollData: any) => {
                return {
                    type: 'normal',
                    data: rollData,
                    timestamp: rollData.timestamp,
                };
            });
            const dmRolls = this.dmRolls.map((rollData: any) => {
                return {
                    type: 'dm',
                    data: rollData.result,
                    timestamp: rollData.timestamp,
                };
            });
            return normalRolls
                .concat(dmRolls)
                .sort(
                    (
                        a: { type: string; data: any; timestamp: number },
                        b: { type: string; data: any; timestamp: number }
                    ) => {
                        return a.timestamp - b.timestamp;
                    }
                );
        },
    },
});
