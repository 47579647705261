
import Vue from 'vue';
import API from '@/api';

export default Vue.extend({
    name: 'JoinGame',
    data() {
        return {
            gameId: '',
            playerId: '',
            enterAsDM: false,
            invalidDM: false,
            invalidDMMessage: '',
        };
    },
    methods: {
        async joinGame(): Promise<void> {
            try {
                await API.post(`/join/${encodeURIComponent(this.gameId)}`, {
                    playerId: this.playerId,
                    enterAsDM: this.enterAsDM,
                });

                if (this.enterAsDM) {
                    this.$store.dispatch('setAsDM');
                }
                this.$emit(
                    'joinedGame',
                    this.gameId,
                    this.playerId,
                    this.enterAsDM
                );
            } catch (err: any) {
                console.error('Error joining game: ', err);
                if (err?.response?.status === 409) {
                    this.invalidDMMessage = err.response.data;
                    this.showInvalidDM();
                }
            }
        },
        submitIfEnter(event: KeyboardEvent) {
            if (this.gameId.length === 0 || this.playerId.length === 0) {
                return;
            }

            if (event.key === 'Enter') {
                this.joinGame();
            }
        },
        showInvalidDM() {
            this.invalidDM = true;
        },
    },
    computed: {},
});
