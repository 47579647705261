import { render, staticRenderFns } from "./JoinGame.vue?vue&type=template&id=fb3bb936&scoped=true&"
import script from "./JoinGame.vue?vue&type=script&lang=ts&"
export * from "./JoinGame.vue?vue&type=script&lang=ts&"
import style0 from "./JoinGame.vue?vue&type=style&index=0&id=fb3bb936&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb3bb936",
  null
  
)

export default component.exports