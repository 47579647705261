
import Vue from 'vue';

export default Vue.extend({
    name: 'IndividualDie',
    props: {
        dieType: {
            type: Number,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
        ignore: {
            type: Boolean,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        static: Boolean,
    },
    data() {
        return {
            icons: {
                20: 'hexagon-fill',
                12: 'pentagon-fill',
                10: 'diamond-fill',
                8: 'diamond-fill',
                6: 'square-fill',
                4: 'triangle-fill',
            } as { [key: number]: string },
            rolling: true,
            rollTimeMs: 1000,
        };
    },
    created() {
        if (this.static) {
            this.rolling = false;
        }
    },
    mounted() {
        setTimeout(() => {
            this.rolling = false;
            this.$emit('done');
        }, this.rollTimeMs + this.index * 300);
    },
    computed: {
        dieIcon(): string {
            return this.icons[this.dieType];
        },
        crit(): boolean {
            if (this.dieType !== 20) return false;

            return this.value === 20;
        },
        fail(): boolean {
            if (this.dieType !== 20) return false;

            return this.value === 1;
        },
    },
});
