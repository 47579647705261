
import Vue from 'vue';

import Marker from '@/models/Marker';

export default Vue.extend({
    name: 'EntityMarker',
    props: {
        marker: Marker,
        dragging: Boolean,
    },
    methods: {
        async pickUp(event: MouseEvent): Promise<void> {
            event.preventDefault();

            if (event.button !== 2) {
                this.$emit('pickUp', {
                    marker: this.marker,
                    ref: this.$refs.marker,
                });
            }
        },
        edit(event: MouseEvent): void {
            event.preventDefault();
            this.$emit('editMarker', this.marker);
        },
    },
    computed: {
        markerStyles(): { [key: string]: string } {
            const cursor = this.dragging ? 'grabbing' : 'grab';
            const shadow =
                this.marker.height > 0
                    ? 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))'
                    : 'drop-shadow(0)';
            return {
                '--hover-cursor': cursor,
                '--marker-shadow': shadow,
            };
        },
        borderColor(): string {
            return this.marker.condition.length > 0 ? 'red' : 'black';
        },
        markerHeight(): string {
            return +this.marker.height > 0 ? `${this.marker.height}ft` : '';
        },
    },
});
